import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { v4 } from "uuid"
import {
  Section,
  SectionTitle,
  SectionLead,
  MarkdownContent,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/RvCarportMap"
import LocationStyle from "../components/LocationStyle"
// import StateSale from "../components/StateSale"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const ListAreas = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  ${BreakpointUp.sm`
        column-count: 3;
    `}
  ${BreakpointUp.lg`
        column-count: 4;
    `}
`
const ListItem = styled.li`
  padding-left: 20px;
  vertical-align: middle;
  margin: 0;
  line-height: 26px;
  + li {
    margin-top: 10px;
  }
  &:before {
    content: url(${ListArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const LoadMore = styled.button`
  display:block;
  margin:0 auto;
`

const State = ({ data, location, pageContext: { category } }) => {
  const [ limit, Setlimit ] = useState(20);
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  const pageData = data.contentfulState
  const productCategory = category
  const relatedProductsData = []
      
  data.allContentfulProduct.edges.map((product) => {
    if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
      relatedProductsData.push(product.node)
    } else {
      product.node.productSubCategories.map((item) => {
        if(item.name.toLowerCase() === productCategory.split("-").join(" ")) {
          relatedProductsData.push(product.node)
        }
      })
    }
  })

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Layout location={location}>
      <Seo
        title={`RV Carports ${pageData.name} | Metal RV Covers ${pageData.name}, ${pageData.abbreviation} | Prefab RV Carport Kits ${pageData.abbreviation}`}
        description={`Buy RV Carports in ${pageData.name}. Our prefab metal RV covers offer the best protection to your recreational vehicle against rain, snow and UV radiation in ${pageData.name}, ${pageData.abbreviation}.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0 overflow-initial"
      >
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal RV Covers ${pageData.name}`}</h1>
                <p>
                  No matter where you live in {pageData.name}, you need
                  dependable shelter to protect and prolong the life of your RV
                  investment. A metal RV carport is a smart, convenient way to
                  safeguard your RV, motorhome, camper, or trailer!
                  Quality-built steel RV covers and garages are available from
                  Coast To Coast Carports, Inc. throughout{" "}
                  {pageData.abbreviation}, and our structures are built-to-last!
                </p>
                <p>At Coast to Coast Carports, we offer a wide range of highly durable RV carports, including regular roof RV carports, a-frame roof RV carports, and vertical roof RV carports. These steel carports in {pageData.name} are capable of withstanding heavy weather conditions. All our steel carports are moisture-resistant and pest-resistant, keeping your RV, fifth-wheels, trailers, motorhomes, and other recreational vehicles safe and secure for years. In addition to storing RVs, these carports can also be used for auto repair shops, agricultural equipment storage, and for residential needs. If you need a secure space for your expensive recreational vehicles, give our building experts a call at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a>, and we will help you design and customize the structure you have always wanted.</p>
                <h2>Features of Metal RV Carports {pageData.name}:</h2>
                <h3>{pageData.name} RV Carports to Match Your Lifestyle:</h3>
                <p>
                  If you’re an {pageData.name} RV owner, you know that an RV is
                  more than just a vehicle – it’s an opportunity to experience
                  the adventures of the open road in a whole new way! You’re
                  free to travel at your own pace and stay almost anywhere, as
                  your lodging and meals are conveniently built in with a
                  well-stocked RV. Still, most of us aren’t able to answer the
                  call of the open road as much as we’d like, and chances are
                  your RV sits parked for most of the year.
                </p>

                <h3>
                  Protect Your Investment with an {pageData.name} Metal RV
                  Cover:
                </h3>
                <p>
                  Looking for the best storage option to keep your RV safe from{" "}
                  {pageData.name}’s sometimes trying weather? Take a look at our
                  high-quality metal carports and covers. At Coast To Coast
                  Carports, we’re the specialists in crafting and installing
                  durable storage buildings of all sizes and shapes. Our steel
                  RV carports are both sturdy and attractive, and they provide
                  the reliable protection you need so your RV will be ready to
                  go when you are!
                </p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                {/* {(pageData.abbreviation === "AR" || pageData.abbreviation === "IL" || pageData.abbreviation === "NM" || pageData.abbreviation === "CO" || pageData.abbreviation === "ID") && <StateSale state={pageData.abbreviation} /> } */}
                <PrimaryForm states={data.allContentfulState.edges}  location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data={pageData.locationsForMainBuildingCategories} />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {pageData.name}’s RV Covers</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle state={pageData.name} />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="30px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle textAlign="left" maxWidth="910px">
            Roof Style Options for Your {pageData.name} Metal RV Carport:
          </SectionTitle>
          <MarkdownContent maxWidth="910px" mb="30px" textAlign="left">
            <p>
              Choose from the following roof options for your {pageData.name}{" "}
              steel RV carport from Coast To Coast Carports:
            </p>

            <h3>Regular Style Metal RV Covers – GOOD</h3>
            <p>
              This roof style is the most economical, with corrugated ridges
              that run from end to end. This roof style is great if you live in
              an area that doesn’t typically experience much heavy wind or rain.
              A regular roof is perfect for protecting your RV and other items
              from excessive UV exposure.
            </p>

            <h3>A-Frame Metal RV Covers – BETTER</h3>
            <p>
              The A-frame is a stronger design than the regular roof, and it
              performs better for areas that receive more challenging weather
              conditions. Because Idaho is a state that can experience weather
              extremes, we recommend the extra durability and strength of a
              vertical A-frame roof for all of our {pageData.name} metal
              building customers. The vertical roof has more reinforcement than
              other styles, and its vertical design allows rain and snow to run
              off the roof more effortlessly, helping to prevent heavy,
              load-bearing accumulations.
            </p>

            <h2>
              Metal RV Carports {pageData.name} – Steel RV Carports{" "}
              {pageData.abbreviation}:
            </h2>
            <p>
              Coast To Coast Carports won’t leave you worrying about how to get
              the building to your site, or with the headache of trying to put
              it up yourself. We are happy to provide full delivery and
              installation services! All you need to do is ensure that your
              installation site is level before we arrive with your building.
              Let the experts at Coast To Coast Carports take care of everything
              else!
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionEditor>
            <SectionEditorLeft>
              <h2>
                Why Choose Coast to Coast for RV Carports in {pageData.name}:
              </h2>
            </SectionEditorLeft>
            <SectionEditorRight>
              <p>
                From our local manufacturing facilities in AR, TX, ID, and OR,
                Coast To Coast Carports stands ready to take care of all your{" "}
                {pageData.name} RV carport needs. We can deliver and install
                your metal RV cover anywhere in {pageData.abbreviation}, and our
                expert team will do the job right for you the first time, and
                every time! Let us help you take care of your home-on-the-road
                when you’re not out on the road!
              </p>
              <p>
                Coast To Coast Carports can construct and install everything
                from carports to RV covers to sheds to garages to entire storage
                units. We’ve been in business since 2001, helping customers in{" "}
                {pageData.name} and across the country to get prefab,
                custom-engineered metal structures to meet any residential,
                commercial, or agricultural needs. Reach out to us at Coast To
                Coast Carports today, and let us show you why we’re the best in{" "}
                {pageData.abbreviation}!
              </p>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>
                Why Choose Coast to Coast for Metal Carports in {pageData.name}:
              </h2>
              <p>
                From our local manufacturing facilities in AR, TX, ID, and OR,
                Coast To Coast Carports stands ready to take care of all your{" "}
                {pageData.name} carport needs. We can deliver and install your
                metal carport anywhere in {pageData.abbreviation}, and our
                expert team will do the job right for you the first time, and
                every time! Coast To Coast Carports can construct and install
                everything from carports to RV covers to sheds to garages to
                entire storage units. We’ve been in business since 2001, helping
                customers in {pageData.name} and across the country to get the
                prefab, custom-engineered metal structure to meet any
                residential, commercial, or agricultural needs. Reach out to us
                at Coast To Coast Carports today, and let us show you why we’re
                the best in {pageData.abbreviation}!
              </p>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="0">Areas We Serve In {pageData.name}</SectionTitle>
          <SectionLead mb="40px" maxWidth="900px">
            We cover all locations throughout the state of {pageData.name},
            including:
          </SectionLead>
          <ListAreas>
          {pageData.cities.slice(0, limit).map(item => {
              return (
                <ListItem key={v4()}>
                {item.content}
                </ListItem>
              )
            })}
          </ListAreas>
          {limit !== pageData.cities.length && 
          
            <LoadMore onClick={()=> Setlimit(pageData.cities.length)}><PrimaryButton text="Load More" /></LoadMore>
          }
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default State

export const pageQuery = graphql`
  query RVStateLandingQuery($id: String!, $name: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      cities {
        content
      }
      locationsForMainBuildingCategories {
        rvCarportsAddress
        rvCarportsContactNumber
        rvCarportsLocationLink
        rvCarportsMapEmbedLink {
          rvCarportsMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationsForMainBuildingCategories {
            rvCarportsAddress
            rvCarportsContactNumber
            rvCarportsLocationLink
            rvCarportsMapEmbedLink {
              rvCarportsMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: {installationStates: {elemMatch: {name: {glob: $name}}}}
    ) {
      edges {
        node {
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          productSubCategories {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
