import React from "react"
import styled from "styled-components"
import { MapIcon, PhoneLineIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"

const MapWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* padding-top:30px;
  ${BreakpointUp.md`
    padding-top:60px;
  `}
  ${BreakpointUp.lg`
    padding-top:0;
  `} */
  
`
const MapLeft = styled.div`
  position: relative;
  width: 100%;  
  ${BreakpointUp.md`
    flex: 0 0 calc(58.333333% + 20px);
    max-width: calc(58.333333% + 20px);
  `}

  ${BreakpointUp.lg`
    flex: 0 0 calc(58.333333% + 35px);
    max-width: calc(58.333333% + 35px);
    
  `}
`
const MapRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md`
    flex: 0 0 calc(41.666667% - 20px);
    max-width: calc(41.666667% - 20px);
  `}
  ${BreakpointUp.lg`
    flex: 0 0 calc(41.666667% - 35px);
    max-width: calc(41.666667% - 35px);
  
  `}

`
const Address = styled.address`
  margin-bottom: 0;
  font-style: inherit;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`    
    padding:50px;
  `}
  > strong {
    display: block;
    color: #0b619b;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    ${BreakpointUp.lg`    
      font-size: 24px;
      line-height: 34px;
    `}
    ${BreakpointUp.lg`    
      font-size:28px;
      line-height:38px;
      font-weight:900;
    `}
  }
`
const ListInfo = styled.ul`
  margin: 0;
  padding: 0;
  font-weight: 700;
  ${BreakpointUp.lg`            
    font-size:18px;
    font-weight:700;
  `}
`
const ListInfoItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  + li {
    margin-top: 10px;
  }
`
const AddressContainer = styled.div`
  background-color: #0b619b;
  height: 100%;
  display: flex;
  align-items: center;
  address {
    strong {
      color: #fff;
    }
    .icon {
      svg {
        fill: #fff;
      }
      + .text {
        margin-left: 15px;
      }
    }
    .text {
      color: #fff;
      &:hover{
        color:rgba(255,255,255,0.6);
      }
    }
  }
`

const RvCarportMap = ({data}) => {
  
  if (data==null) {
    return null;
  } else {

  return (
   <>
   {data.map((state,index)=>{
      return(
        <MapWrap>
        <MapLeft>
            <div className="embed-responsive embed-responsive-16by3">
            <iframe src={state.rvCarportsMapEmbedLink.rvCarportsMapEmbedLink} allowfullscreen="" loading="lazy" title="map"></iframe>
            </div>
        </MapLeft>
        <MapRight>
            <AddressContainer>
            <Address>
                <strong>Coast To Coast Carports</strong>
                <ListInfo>
                <ListInfoItem>
                    <span className="icon"><MapIcon /></span>
                    <a className="text" href={state.rvCarportsLocationLink} target="_blank" rel="noreferrer">{state.rvCarportsAddress}</a>
                </ListInfoItem>
                <ListInfoItem>
                    <span className="icon"><PhoneLineIcon /></span>
                    <a className="text" href="tel:8666817846" aria-label="phone number">(866) {state.rvCarportsContactNumber}</a>
                </ListInfoItem>
                </ListInfo>
            </Address>
            </AddressContainer>
        </MapRight>
    </MapWrap>      
      )  
   })}
    
    </>
    //  })
  )
  }
}

export default RvCarportMap
